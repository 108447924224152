import React from "react";
import P from "~components/typography/P";

interface Props {
	title: string;
	description?: string;
	id: string;
	name: string;
}

const Checkbox: React.FC<Props> = ({ title, description, id, name }) => {
	return (
		<div className="relative flex items-start">
			<div className="flex h-5 items-center">
				<input
					id={id}
					name={name}
					type="checkbox"
					className="h-6 w-6 cursor-pointer rounded border-gray-300 text-primary-600 focus:ring-primary-300"
				/>
			</div>
			<div className="ml-3 text-sm">
				<label htmlFor={id} className="cursor-pointer font-medium text-gray-700">
					{title}
				</label>
				{description ? <P>{description}</P> : null}
			</div>
		</div>
	);
};

export default Checkbox;

const validateData = (formId: string, nextStep: () => void) => {
	const inputs = document.getElementById(formId)?.querySelectorAll("input");
	const errors = [];

	if (inputs && inputs.length > 0) {
		inputs.forEach((input) => {
			if (input.checkValidity() === false) {
				input.reportValidity();
				errors.push("Error");
			}
		});
	}
	if (errors.length === 0) {
		nextStep();
	}
};
export default validateData;

import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import Input from "~components/forms/elements/Input";

interface Props {
	name: string;
	value: string;
	id: string;
	label: string;
	svg: React.ReactNode;
	secondaryInput?: {
		type: string;
		pattern: string;
		label: string;
		id: string;
		name: string;
		placeholder: string;
	};
}

const CheckboxCard: React.FC<Props> = ({ name, value, id, label, svg, secondaryInput }) => {
	const [isOn, setIsOn] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsOn(e.target.checked);
	};

	return (
		<Wrapper div={secondaryInput ? true : false}>
			<div className="relative flex w-full self-stretch">
				<input className="hidden" type="checkbox" name={name} value={value} id={id} onChange={handleChange} />
				{isOn && <CheckIcon className="absolute top-2 right-2 h-8 w-8 rounded-full bg-blue-500 p-2 text-white" />}
				<label
					className={`relative w-full cursor-pointer self-stretch rounded border border-transparent p-5 text-center shadow transition-colors duration-300 ${
						isOn ? "border-blue-400" : ""
					}`}
					htmlFor={id}
				>
					<div className="svg-checkbox-container">
						<div className={`text-center ${isOn ? "text-blue-500" : ""}`}>{svg}</div>
						<div className={`${isOn ? "text-blue-500" : ""}`}>{label}</div>
					</div>
				</label>
			</div>

			{secondaryInput && isOn ? (
				<Input
					type={secondaryInput.type}
					pattern={secondaryInput.pattern}
					label={secondaryInput.label}
					id={secondaryInput.id}
					name={secondaryInput.name}
					placeholder={secondaryInput.placeholder}
					className="mt-4"
					required
				/>
			) : null}
		</Wrapper>
	);
};

export default CheckboxCard;

interface PropsWrapper {
	div: boolean;
	children: React.ReactNode;
}

const Wrapper: React.FC<PropsWrapper> = ({ div = false, children }) => {
	if (div) return <div className="w-full">{children}</div>;
	return <>{children}</>;
};

import React from "react";

interface Props {
	label: string;
	id: string;
	name: string;
	placeholder: string;
	rows?: number;
	required?: boolean;
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
	onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
	className?: string;
}

const Textarea: React.FC<Props> = ({
	label,
	id,
	name,
	placeholder,
	rows = 4,
	required = false,
	onChange,
	onKeyDown,
	className,
}) => {
	return (
		<div>
			<label className="mb-4 block text-left font-bold text-gray-600" htmlFor={id}>
				{label}
				{required && "*"}
			</label>

			<textarea
				cols={50}
				rows={rows}
				className={`w-full appearance-none rounded border border-gray-500 border-opacity-50 px-5 py-3 text-gray-600 placeholder-gray-400 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-inset focus:ring-primary-300 ${
					className ? className : ""
				}`}
				id={id}
				name={name}
				placeholder={placeholder}
				required={required}
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
		</div>
	);
};

export default Textarea;

import React from "react";
import Button from "~components/common/Button";

interface Props {
	onClick?: () => void;
	submit?: boolean;
	className?: string;
}

const NextButton: React.FC<Props> = ({ onClick = undefined, submit = undefined, className }) => {
	return <Button submit={submit} onClick={onClick} text={submit ? "Jetzt anfragen" : "Weiter"} className={className} />;
};

export default NextButton;

import React from "react";
import Button from "~components/common/Button";

interface Props {
	onClick: () => void;
	className?: string;
}

const PrevButton: React.FC<Props> = ({ onClick = undefined, className }) => {
	return <Button onClick={onClick} text={"Zurück"} className={className} type="secondary" showArrow={false} />;
};

export default PrevButton;

import React from "react";
import Description from "~components/forms/elements/Description";
import PrevButton from "~components/forms/elements/PrevButton";
import NextButton from "~components/forms/elements/NextButton";
import Textarea from "~components/forms/elements/Textarea";
import Datenschutz from "~components/forms/elements/Datenschutz";

interface Props {
	prevStep: () => void;
	nextStep: () => void;
}

const CallBack: React.FC<Props> = ({ prevStep, nextStep }) => {
	return (
		<div>
			<Description
				headline="Hast Du noch was auf den Herzen?"
				description="Teile uns mit, ob Du noch was zu sagen hast."
			/>

			<Textarea label="Kommentar" id="kommentar" name="Kommentar" placeholder="Dein Kommentar" className="mb-4" />
			<div className="mb-3 grid grid-cols-2 gap-3">
				<PrevButton onClick={prevStep} />
				<NextButton onClick={nextStep} submit />
			</div>
			<Datenschutz />
		</div>
	);
};

export default CallBack;

import React, { useState } from "react";
import Wizard from "~components/forms/elements/Wizard";
import Description from "~components/forms/elements/Description";
import PrevButton from "~components/forms/elements/PrevButton";
import NextButton from "~components/forms/elements/NextButton";
import MainData from "~components/forms/blocks/MainData";
import CallBack from "~components/forms/blocks/CallBack";
import Comment from "~components/forms/blocks/Comment";
import Checkbox from "~components/forms/elements/Checkbox";
import H4 from "~components/typography/H4";

const CoachingForm: React.FC = () => {
	const [step, setStep] = useState(0);
	const formName = "Coaching";

	const prevStep = () => {
		setStep((prevCount) => prevCount - 1);
	};

	const nextStep = () => {
		setStep((prevCount) => prevCount + 1);
	};

	return (
		<form name={formName} method="POST" data-netlify="true" action="/anfrage-gesendet/">
			<input type="hidden" name="form-name" value={formName} />
			<Wizard currentStep={step}>
				<div>
					<Description
						headline="Wo siehst Du bei Dir aktuell Lücken?"
						description="Klicke kurz an, welche Lücken Du bei dir siehst und worauf Du Deine Prioritäten setzten möchtest."
					/>

					<H4 className="mb-4">Führung</H4>
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
						<Checkbox
							title="Vom Mitarbeiter zur Führungskraft"
							name="Vom Mitarbeiter zur Führungskraft"
							id="fuehrung-1"
						/>
						<Checkbox
							title="Kommunikation und soziale Kompetenz"
							name="Kommunikation und soziale Kompetenz"
							id="fuehrung-2"
						/>
						<Checkbox title="Change Management" name="Change Management" id="fuehrung-3" />
						<Checkbox title="Virtuelle Führung" name="Virtuelle Führung" id="fuehrung-4" />
						<Checkbox title="Female Ledership" name="Female Ledership" id="fuehrung-5" />
					</div>
					<hr className="my-8" />

					<H4 className="mb-4">Persönlichkeitsentwicklung</H4>
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
						<Checkbox
							title="Allgemeine Persönlichkeitsentwicklung"
							name="Allgemeine Persönlichkeitsentwicklung"
							id="persoenlichekeit-1"
						/>
						<Checkbox title="Resilienz-Seminar" name="Resilienz-Seminar" id="persoenlichekeit-2" />
						<Checkbox title="Positives Denken" name="Positives Denken" id="persoenlichekeit-3" />
						<Checkbox title="Stressbewältigung" name="Stressbewältigung" id="persoenlichekeit-4" />
						<Checkbox title="Erfolg durch Charisma" name="Erfolg durch Charisma" id="persoenlichekeit-5" />
					</div>
					<hr className="my-8" />

					<H4 className="mb-4">Verkauf</H4>
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
						<Checkbox title="Verkaufstraining" name="Verkaufstraining" id="verkauf-1" />
						<Checkbox title="Außendienst" name="Außendienst" id="verkauf-2" />
						<Checkbox title="Kundenorientierung" name="Kundenorientierung" id="verkauf-3" />
					</div>
					<hr className="my-8" />
					<H4 className="mb-4">Personalentwicklung</H4>
					<div className="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
						<Checkbox
							title="Einstellungsgespräche führen und Bewerberauswahl"
							name="Einstellungsgespräche führen und Bewerberauswahl"
							id="personal-1"
						/>
						<Checkbox title="Potenzialanalyse" name="Potenzialanalyse" id="personal-2" />
					</div>

					<div className="grid grid-cols-2 gap-3">
						<NextButton onClick={() => nextStep()} className="col-start-2" />
					</div>
				</div>

				<MainData prevStep={prevStep} nextStep={nextStep} step={step} />
				<CallBack prevStep={prevStep} nextStep={nextStep} />
				<Comment prevStep={prevStep} nextStep={nextStep} />
			</Wizard>
		</form>
	);
};

export default CoachingForm;

import React from "react";

const Honeypot: React.FC = () => {
	return (
		<p className="hidden">
			<label>
				Don’t fill this out if you’re human: <input name="bot-field" />
			</label>
		</p>
	);
};

export default Honeypot;

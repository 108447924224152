import React from "react";
import CheckboxCard from "~components/forms/elements/CheckboxCard";

const svg = {
	morgens: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
			<line x1="3" y1="21" x2="21" y2="21" />
			<path d="M12 9v-6l3 3m-6 0l3 -3" />
		</svg>
	),
	mittags: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<circle cx="12" cy="12" r="4" />
			<path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" />
		</svg>
	),
	abends: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
			<line x1="3" y1="21" x2="21" y2="21" />
			<path d="M12 3v6l3 -3m-6 0l3 3" />
		</svg>
	),
};

const CallBackTime: React.FC = () => {
	return (
		<div className="mb-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
			<CheckboxCard
				label="Morgens"
				name="Erreichbar Morgens"
				value="Ja"
				id="erreichbarkeit-morgens"
				svg={svg.morgens}
			/>

			<CheckboxCard
				label="Mittags"
				name="Erreichbar Mittags"
				value="Ja"
				id="erreichbarkeit-mittags"
				svg={svg.mittags}
			/>

			<CheckboxCard label="Abends" name="Erreichbar Abends" value="Ja" id="erreichbarkeit-abends" svg={svg.abends} />
		</div>
	);
};

export default CallBackTime;

// Packages
import React from "react";
import { FingerPrintIcon, ShieldCheckIcon, ViewListIcon } from "@heroicons/react/outline";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid";
import FeatureIcon from "~components/features/FeatureIcon";

interface Props {
	children: React.ReactNode;
}

const Enquiery: React.FC<Props> = ({ children }) => {
	return (
		<div className="grid grid-cols-1 gap-8 md:grid-cols-3">
			<div className="max-w-3xl p-6 md:order-2 md:col-span-2">{children}</div>
			<aside className="md:oder-1 bg-slate-100 p-6">
				<div className="flex-col lg:flex">
					<button
						onClick={() => window.history.back()}
						className="hover:text-brand-yellow-dark mb-8 inline-flex w-full transition duration-300"
					>
						<ArrowCircleLeftIcon className="text-brand-yellow-dark mr-3 h-6 w-6" />
						Zurück zur letzten Seite
					</button>

					<div className="mb-5 w-full text-left">
						<FeatureIcon
							icon={ShieldCheckIcon}
							title="Verschlüsselte Übertragung"
							description="Deine Daten werden per SSL-Verschlüsselung übertragen."
							color="blue"
							align="left"
							iconSize="small"
						/>
					</div>
					<div className="mb-5 w-full text-left">
						<FeatureIcon
							icon={ViewListIcon}
							title="Respekt vor Daten"
							description="Wir erheben nur Daten, die zur Bearbeitung Deiner Anfrage benötigt werden."
							color="blue"
							align="left"
							iconSize="small"
						/>
					</div>
					<div className="mb-5 w-full text-left">
						<FeatureIcon
							icon={FingerPrintIcon}
							title="Keine Weitergabe"
							description="Deine Daten werden nur verwendet, um Deine Anfrage zu bearbeiten. Wir geben Deine Daten nicht weiter & verwenden diese nicht anderweitig."
							color="blue"
							align="left"
							iconSize="small"
						/>
					</div>
				</div>
			</aside>
		</div>
	);
};

export default Enquiery;

import React from "react";
import { Link } from "gatsby";
import P from "~components/typography/P";

interface Props {
	title: string;
	description: string;
	color?: Color;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon: JSX.Element | any;
	align?: "left" | "center";
	iconSize?: "small" | "big";
	to?: string;
}

export type Color = "gray" | "red" | "orange" | "yellow" | "blue" | "purple" | "teal" | "green";

const FeatureIcon: React.FC<Props> = ({
	title,
	description,
	color = "gray",
	icon,
	align = "center",
	iconSize = "big",
	to,
}) => {
	const IconElement = icon;

	let iconColor = "text-gray-900";
	let bgColor = "bg-gray-900";

	switch (color) {
		case "gray":
			iconColor = "text-gray-900";
			bgColor = "bg-gray-900";
			break;
		case "red":
			iconColor = "text-red-900";
			bgColor = "bg-red-900";
			break;
		case "orange":
			iconColor = "text-orange-900";
			bgColor = "bg-orange-900";
			break;
		case "yellow":
			iconColor = "text-yellow-900";
			bgColor = "bg-yellow-900";
			break;
		case "blue":
			iconColor = "text-blue-900";
			bgColor = "bg-blue-900";
			break;
		case "purple":
			iconColor = "text-purple-900";
			bgColor = "bg-purple-900";
			break;
		case "teal":
			iconColor = "text-teal-900";
			bgColor = "bg-teal-900";
			break;
		case "green":
			iconColor = "text-green-900";
			bgColor = "bg-green-900";
			break;
	}

	return (
		<div>
			<dt className={`flex flex-col ${align === "center" ? "items-center" : "items-start"}`}>
				<div
					className={`flex ${
						iconSize === "big" ? "mb-5 h-16 w-16" : "mb-3 h-8 w-8"
					} items-center justify-center rounded-full ${bgColor} bg-opacity-10`}
				>
					{icon ? (
						<IconElement className={`${iconColor} ${iconSize === "big" ? "h-8 w-8" : "h-4 w-4"}`} aria-hidden="true" />
					) : null}
				</div>
				<P className="mb-5 font-bold" textColor="text-gray-900">
					{title}
				</P>
			</dt>
			<P as="dd" className={align === "center" ? "text-center" : undefined}>
				{description}
			</P>
			{to && (
				<P className="mt-4 text-center">
					<Link to={to} className="text-primary hover:underline">
						Mehr erfahren
					</Link>
				</P>
			)}
		</div>
	);
};

export default FeatureIcon;

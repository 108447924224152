import React from "react";
import Description from "~components/forms/elements/Description";
import PrevButton from "~components/forms/elements/PrevButton";
import NextButton from "~components/forms/elements/NextButton";
import CallBackTime from "~components/forms//elements/CallBackTime";

interface Props {
	prevStep: () => void;
	nextStep: () => void;
}

const CallBack: React.FC<Props> = ({ prevStep, nextStep }) => {
	return (
		<div>
			<Description
				headline="Wann bist Du erreichbar?"
				description="Diese Informationen sind wichtig, damit wir Dich nicht zu
  unpassenden Zeiten kontaktieren."
			/>

			<CallBackTime />
			<div className="grid grid-cols-2 gap-3">
				<PrevButton onClick={prevStep} />
				<NextButton onClick={nextStep} />
			</div>
		</div>
	);
};

export default CallBack;

import React from "react";
import validateData from "~components/forms/functions/validateData";
import Description from "~components/forms/elements/Description";
import Input from "~components/forms/elements/Input";
import Honeypot from "~components/forms/elements/Honeypot";
import PrevButton from "~components/forms/elements/PrevButton";
import NextButton from "~components/forms/elements/NextButton";

interface Props {
	prevStep: () => void;
	nextStep: () => void;
	step: number;
}

const MainData: React.FC<Props> = ({ prevStep, nextStep, step }) => {
	return (
		<div id="mainData">
			<Description
				headline="Kontaktdaten"
				description="Diese Informationen sind wichtig, damit wir Dich kontaktieren können."
			/>

			<div className="mb-4 grid grid-cols-2 gap-4">
				<div>
					<Input type="text" label="Vorname" id="vorname" name="Vorname" placeholder="Dein Vorname" required />
				</div>
				<div>
					<Input type="text" label="Nachname" id="nachname" name="Nachname" placeholder="Dein Nachname" required />
				</div>
			</div>

			<Honeypot />

			<Input
				type="tel"
				label="Telefon"
				id="telefon"
				name="Telefon"
				placeholder="Deine Telefonnummer"
				autoComplete="tel"
				inputMode="numeric"
				className="mb-4"
				required
			/>

			<Input
				type="email"
				label="E-Mail"
				id="email"
				name="E-Mail"
				placeholder="Deine E-Mailadresse"
				autoComplete="email"
				className="mb-4"
				required
			/>
			<div className="grid grid-cols-2 gap-3">
				{step > 0 ? <PrevButton onClick={prevStep} /> : null}
				<NextButton onClick={() => validateData("mainData", nextStep)} className={step === 0 ? "col-start-2" : ""} />
			</div>
		</div>
	);
};

export default MainData;

import React from "react";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";

interface Props {
	headline: string;
	description: string;
}

const Headline: React.FC<Props> = ({ headline, description }) => {
	return (
		<div className="mb-4">
			<H3>{headline}</H3>
			<P>{description}</P>
		</div>
	);
};

export default Headline;

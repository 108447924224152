/* eslint-disable react/no-array-index-key */
import React from "react";

interface Props {
	currentStep: number;
	children: React.ReactNode[];
}

const Wizard: React.FC<Props> = ({ currentStep, children }) => {
	return (
		<div>
			{children.map((child, i) => {
				if (i === currentStep) {
					return <div key={`wizard-step-${i}`}>{child}</div>;
				}
				return (
					<div className="hidden" key={`wizard-step-${i}`}>
						{child}
					</div>
				);
			})}
		</div>
	);
};

export default Wizard;
